/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Footer from '../containers/Layout/Footer';
import Header from '../containers/Layout/Header';
import UnitPage from '../containers/unit-page';

const Bacacheri = ({ location }) => (
  <>
    <SEO
      title="Curso Preparatório Garra - Unidade Bacacheri"
      description="Você está buscando um preparatório para concursos públicos em Curitiba? Conheça o Curso Preparatório Garra na Unidade Bacacheri. Saiba mais!"
      location={location}
    />
    <Header />
    <UnitPage unit="bacacheri" />
    <Footer />
  </>
);

Bacacheri.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Bacacheri;
